import { gsap, MotionPathPlugin } from 'gsap/all';
import AbstractComponent from "./abstract-component";

export default class RotatingSteps extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.positions = [[0.75, 0.05, 0.45], [1.05, 0.45, 0.75], [1.45, 0.75, 1.05]]; // Positions initiales
        this.currentIndex = 0
        this.initDomElements();
        MotionPathPlugin.convertToPath("#circlePath");
        this.updateAnimations();
        this.initEventListeners();
    }

    initDomElements() {
        this.DOM.numbers = [...this.$el.querySelectorAll('.number')];
        this.DOM.items = [...this.$el.querySelectorAll('.item')];
        this.DOM.arrows = [...this.$el.querySelectorAll('.c-rotating-steps__arrow')];
        
        gsap.set(this.DOM.items.slice(1), {
            autoAlpha:0,
        })
    }

    initEventListeners() {
        window.addEventListener('resize', () => {
            this.updateAnimations()
        })
        this.DOM.numbers.forEach(number => {
            number.addEventListener('click',()=>{
                const amount = number.getAttribute('data-index')
                this.setAnimations(amount - this.currentIndex)   
            })

        });
        this.DOM.arrows.forEach((arrow, index) => {
            arrow.addEventListener('click', () => {
                if (!arrow.disabled) {
                    if (arrow.classList.contains('left')) {
                        this.setAnimations(-1);
                    } else {
                        this.setAnimations(1);
                    }
                }
            });
        });
    }
    animatePreviousItem(){
        gsap.fromTo(this.DOM.items[this.currentIndex], {
            y: 0,
            autoAlpha:1
        }, {
            y: -10,
            autoAlpha:0,
            duration: 0.5,
        })
    }
    animateNextItem(){
        gsap.fromTo(this.DOM.items[this.currentIndex], {
            y: 10,
            autoAlpha:0
        }, {
            y: 0,
            autoAlpha:1,
            duration: 0.5,
            delay:0.5
        })
    }
    setAnimations(amount) {
        if (this.currentIndex + amount >= 0 && this.currentIndex + amount < 3) {
            this.animatePreviousItem()
            this.currentIndex += amount
            this.DOM.arrows[0].disabled = false
            this.DOM.arrows[1].disabled = false
        } else {
            return
        }
        if ((this.currentIndex + amount) == 3) {
            this.DOM.arrows[1].disabled = true
            this.DOM.arrows[0].disabled = false
        }
        if ((this.currentIndex + amount) < 0) {
            this.DOM.arrows[0].disabled = true
            this.DOM.arrows[1].disabled = false
        }
        // Initialiser les animations avec les positions initiales
        this.DOM.numbers.forEach((number, index) => {
            const position = this.positions[this.currentIndex][index];
            gsap.to(
                number,
                {
                    motionPath: {
                        path: "#circlePath",
                        align: "#circlePath",
                        alignOrigin: [0.5, 0.5],
                        autoRotate: false,
                        start: amount < 0 ? this.positions[this.currentIndex + 1][index] : this.positions[this.currentIndex - 1][index], // Position initiale
                        end: position // Position initiale
                    },
                    duration: 1,
                    onStart: () => {
                        this.DOM.arrows.forEach(arrow => {
                            arrow.style.pointerEvents = 'none'
                        })
                    },
                    onComplete: () => {
                        this.DOM.arrows.forEach(arrow => {
                            arrow.style.pointerEvents = ''
                        })
                    }
                }
            );
            this.animateNextItem()
            if (number.classList.contains('active')) {
                number.classList.remove('active')
            }
            if (position == 0.75) {
                // Ajouter la classe active à l'élément à la position 0
                number.classList.add('active');

            }
            // Stocker la position initiale dans l'attribut data-prev-position
            number.setAttribute('data-prev-position', position);
        });


    }
    updateAnimations() {
        // Initialiser les animations avec les positions initiales
        this.DOM.numbers.forEach((number, index) => {
            const position = this.positions[this.currentIndex][index];
            gsap.set(
                number,
                {
                    motionPath: {
                        path: "#circlePath",
                        align: "#circlePath",
                        alignOrigin: [0.5, 0.5],
                        autoRotate: false,
                        end: position // Position initiale
                    },
                }
            );
            // Stocker la position initiale dans l'attribut data-prev-position
            number.setAttribute('data-prev-position', position);
        });

        // Ajouter la classe active à l'élément à la position 0
        this.DOM.numbers[0].classList.add('active');
    }
    destroy() {
        // Ajouter si nécessaire un code pour nettoyer les événements ou autres ressources
    }
}
