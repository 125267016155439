import AbstractComponent from "./abstract-component";

export default class Dropdowns extends AbstractComponent {

    constructor(el) {
        super(el)
        this.selector = '[data-dropdown-toggle]'
        this.DOM = {};
        this.toggleFunction = this.toggle.bind(this)
        this.init();
    }

    init() {
        this.initDomElements();
        this.iniEvents();
    }

    initDomElements() {
        this.dropdowns = document.querySelectorAll(this.selector);
        this.dropdowns.forEach(dropdown => {
            const content = dropdown.querySelector('[data-dropdown-content]');
            if (dropdown.hasAttribute('opened') && content) {
                content.style.maxHeight = `${content.scrollHeight}px`;
            }
        });
    }

    toggle(e) {
        if (e.target != e.currentTarget) {
            return
        }
        e.currentTarget.toggleAttribute('opened');
        const content = e.currentTarget.querySelector('[data-dropdown-content]');
        if (content) {
            if (!content.style.maxHeight) {
                content.style.maxHeight = `${content.scrollHeight}px`;
            } else {
                content.style.maxHeight = "";
            }
        }
        setTimeout(() => {
            this.app.$scroll.update();
        }, 1000);
    }

    setEvent(dropdown) {
        dropdown.addEventListener('click', this.toggleFunction);
    }

    removeEvent(dropdown) {
        dropdown.removeEventListener('click', this.toggleFunction);
    }

    iniEvents() {
        this.dropdowns.forEach(dropdown => {
            this.removeEvent(dropdown);
            if (dropdown.getAttribute('data-dropdown-screen') == "mobile") {
                if (window.matchMedia('(max-width:1189.98px)').matches) {
                    this.setEvent(dropdown);
                }
            } else {
                this.setEvent(dropdown);
            }
        });
    }

    destroy() {}
}
