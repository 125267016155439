import AbstractComponent from "./abstract-component";

  export default class Table extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.items = this.$el.querySelectorAll('.item-button')
        this.DOM.closeItems = this.$el.querySelectorAll('.close')
    }
    initEvents(){
        if(window.matchMedia('(max-width: 879.98px)').matches){
            this.DOM.oldBtn = this.$el.querySelector('.item-button.active')
            this.DOM.oldContent = this.$el.querySelector('.item-content.active')
            if (this.DOM.oldBtn) {
                this.DOM.oldBtn.classList.remove('active')
            }
            if (this.DOM.oldContent) {
                this.DOM.oldContent.classList.remove('active')
            }
            this.DOM.closeItems.forEach(closeItem=>{
                closeItem.addEventListener('click',()=>{
                    this.DOM.oldBtn = this.$el.querySelector('.item-button.active')
                    this.DOM.oldContent = this.$el.querySelector('.item-content.active')
                    if (this.DOM.oldBtn) {
                        this.DOM.oldBtn.classList.remove('active')
                    }
                    if (this.DOM.oldContent) {
                        this.DOM.oldContent.classList.remove('active')
                    }
                })
            })
        }
        this.DOM.items.forEach(item => {
            item.addEventListener('click',()=>{
                this.DOM.oldBtn = this.$el.querySelector('.item-button.active')
                this.DOM.oldContent = this.$el.querySelector('.item-content.active')
                if (this.DOM.oldBtn) {
                    this.DOM.oldBtn.classList.remove('active')
                }
                if (this.DOM.oldContent) {
                    this.DOM.oldContent.classList.remove('active')
                }
                this.$el.querySelector('.item-content[data-tab-index="' + item.getAttribute('data-tab-index') +'"')
                item.classList.add('active')
                let content = this.$el.querySelector('.item-content[data-tab-index="' + item.getAttribute('data-tab-index') +'"')
                content.classList.add('active')
            })
        });
    }
    destroy() { }
}