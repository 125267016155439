

import gsap from "gsap";
import AbstractComponent from "./abstract-component";

  export default class JoinForm extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.form = this.$el.querySelector( '.wpcf7' )
        this.DOM.inputs = this.$el.querySelectorAll('input.wpcf7-form-control,textarea.wpcf7-form-control')
        this.DOM.formWrapper = this.$el.querySelector( '.form-wrapper' )
        this.DOM.sucessMessage = this.$el.querySelector( '.success-message' )

       this.DOM.formWrapper = this.$el.querySelector( '.form-wrapper' )
       this.DOM.sucessMessage = this.$el.querySelector( '.success-message' )
       this.DOM.form = this.$el.querySelector( '.wpcf7' )
       this.DOM.response = this.$el.querySelector('.wpcf7-response-output')
       this.DOM.file = this.$el.querySelector('[data-file-input]')
       if (this.DOM.file) {
            this.DOM.deleteFile = document.querySelector('.delete-file')
            this.DOM.fileInput = this.DOM.file.querySelector('input')
            this.DOM.fileImg = this.DOM.file.querySelector('img')
            this.DOM.fileLabel = this.DOM.file.querySelector('.text')
        }
    }
    initEvents(){
        if (this.DOM.file) {
            this.DOM.fileInput.addEventListener('change',this.handleUpload.bind(this))
            this.DOM.deleteFile.addEventListener('click',this.handleDelete.bind(this)) 
        }

		this.DOM.inputs.forEach(input => {
			input.addEventListener('focus',this.handleFocus.bind(this))
			input.addEventListener('blur',this.handleBlur.bind(this))
		});
        this.DOM.form.addEventListener('wpcf7mailsent',()=>{
            gsap.to(this.DOM.formWrapper,{autoAlpha:0,duration:0.5,onComplete:()=>{
                gsap.set(this.DOM.formWrapper,{display:'none'})
                gsap.set(this.DOM.sucessMessage,{display:'block'})
                gsap.fromTo(this.DOM.sucessMessage,{x:5},{x:0,duration:0.5})
            }})
        })
        if (this.DOM.file) {
            this.DOM.form.addEventListener('wpcf7invalid',(event)=>{
                const cvErrorMessage = this.getErrorMessage("cv",event.detail.apiResponse.invalid_fields);
                setTimeout(() => {
                    if (cvErrorMessage) {
                        this.DOM.response.innerText = cvErrorMessage
                        this.DOM.file.querySelector('label').classList.add('wpcf7-not-valid')
                    }
                }, 100);
            })
        }

    }
    getErrorMessage(fieldName,errorData) {
        const error = errorData.find((error) => error.field === fieldName);
        return error ? error.message : null;
    }
    handleFocus(e){
        const target = e.target
        const container = target.closest('p')
        container.classList.add('focus')
    }
    handleBlur(e){
        const target = e.target
        if (!target.value) {
            const container = target.closest('p')
            container.classList.remove('focus')	
        }
    }
    handleDelete(){
		this.DOM.fileLabel.innerHTML = this.labelContent;
		this.DOM.fileImg.style.display = 'none'
		this.DOM.fileInput.value = '';
		this.DOM.file.classList.remove('has-file')
	}

    handleUpload(e){
		const files = e.target.files;
		const fileName = files[0].name.length > 30 ? files[0].name.substring(0,30) + "...": files[0].name;
		this.labelContent =this.DOM.fileLabel.innerHTML;
		this.DOM.fileLabel.innerHTML = fileName;
		this.DOM.fileImg.style.display = 'block'
        this.DOM.file.querySelector('label').classList.remove('wpcf7-not-valid')
		this.DOM.file.classList.add('has-file')
	}

    destroy() { }
}