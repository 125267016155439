import Swiper from "swiper";
import { Autoplay,FreeMode, Navigation } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode,Autoplay,Navigation]);
  export default class Numbers extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.sliderObject = null;
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
        this.DOM.slides = this.$el.querySelectorAll('.swiper-slide')
        this.DOM.sliderArrowNext = this.$el.querySelector('.c-numbers__arrow.right')
        this.DOM.sliderArrowPrev = this.$el.querySelector('.c-numbers__arrow.left')
        window.addEventListener('resize',()=>{
            this.initSlider();
        })  
    }
    initSlider(){
        if(window.matchMedia('(max-width: 879.98px)').matches){
            this.DOM.slider.querySelector('.swiper-wrapper').classList.remove('row--center')
            this.sliderObject = new Swiper(this.DOM.slider, {
                allowTouchMove:true,
                centeredSlides:true,
                slidesPerView: 1,
                enabled:true,
                navigation:{
                    nextEl:this.DOM.sliderArrowNext,
                    prevEl:this.DOM.sliderArrowPrev,
                  },
              });
        }else{
            if (this.sliderObject) {
                this.sliderObject.destroy(true, true);
                this.resetStyles();
                this.sliderObject = null;
            }

            this.DOM.slider.querySelector('.swiper-wrapper').classList.add('row--center')
        }

    }
    resetStyles() {
        // Reset styles manually
        const swiperWrapper = this.DOM.slider.querySelector('.swiper-wrapper');
        swiperWrapper.style.transform = '';
        swiperWrapper.style.transition = '';
        swiperWrapper.style.width = '';
        this.DOM.slides.forEach(slide => {
            slide.style.width = '';
            slide.style.marginRight = '';
        });
    }
    destroy() { }
}