export default class Cursor {
    constructor(app) {
        this.className = 'c-cursor';
        this.app = app;
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.$el = document.querySelector('.' + this.className);
        this.$hoverElements = document.querySelectorAll('[data-cursor]');
    }

    initEvents() {
        // Update cursor position on mouse move
        window.addEventListener('mousemove', this.handleMouseMove.bind(this));

        // Add active class on hover
        this.$hoverElements.forEach(element => {
            element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
            element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
        });
    }

    handleMouseMove(e) {
        const cursorSize = this.$el.offsetWidth / 2;
        this.$el.style.transform = `translate(${e.clientX - cursorSize}px, ${e.clientY - cursorSize}px)`;
    }

    handleMouseEnter() {
        this.$el.classList.add('active');
    }

    handleMouseLeave() {
        this.$el.classList.remove('active');
    }

}
