import Swiper from "swiper";
import { Autoplay,FreeMode } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, Autoplay]);
  export default class Logos extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
    }
    initSlider(){
        this.sliderObject = new Swiper(this.DOM.slider, {
            loop: true,
            freeMode: {
                enabled: true,
            },
            autoplay: {
                enabled: true,
                delay: 0,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
            },
            allowTouchMove:true,
            centeredSlides:false,
            slidesPerView: 'auto',
            speed:2000,
          });
    }
    destroy() { }
}