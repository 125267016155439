export default {
    'logos': require( './logos.js' ),
    'rotating-steps': require( './rotating-steps.js' ),
    'testimony': require( './testimony.js' ),
    'numbers': require( './numbers.js' ),
    'challenges': require( './challenges.js' ),
    'header': require( './header.js' ),
    'video': require( './video.js' ),
    'customer-case-list': require( './customer-case-list.js' ),
    'article-list': require( './article-list.js' ),
    'push-customer-cases': require( './push-customer-cases.js' ),
    'team': require( './team.js' ),
    'site-footer':require('./site-footer.js'),
    'join-form':require('./join-form.js'),
    'table':require('./table.js')
}
