import AbstractComponent from "./abstract-component";
import YouTubePlayer from "youtube-player";
  export default class Video extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.player = null;
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.video = this.$el.querySelector('[data-video-id]')
        this.DOM.cover = this.$el.querySelector('img')
        this.DOM.playButton = this.$el.querySelector('.c-image-video__play-button')
    }
    initEvents(){

        if (this.DOM.video) {

            this.player = YouTubePlayer(this.DOM.video, {
                videoId: this.DOM.video.getAttribute('data-video-id'),
                host: "https://www.youtube-nocookie.com",
                playerVars: {
                    autoplay: 0,
                    controls:true,
                    color: "white",
                    playsinline: 0,
                },
            });
            this.DOM.cover.addEventListener('click',()=>{
                this.player.playVideo()
                this.app.gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                    this.app.gsap.set([this.DOM.cover,this.DOM.playButton],{display:"none"})
                }})
            })
            this.DOM.playButton.addEventListener('click',()=>{
                this.player.playVideo()
                this.app.gsap.to([this.DOM.cover,this.DOM.playButton],{autoAlpha:0,pointerEvents:"none",duration:1,onComplete:()=>{
                    this.app.gsap.set([this.DOM.cover,this.DOM.playButton],{display:"none"})
                }})
            })
        }

    }
    destroy() { }
}