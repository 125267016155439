import AbstractComponent from "./abstract-component";

export default class Team extends AbstractComponent {
    constructor(el) {
        super(el);
        this.DOM = {};
        this.class = "c-team";
        this.initDomElements();
        this.initResponsiveColumns();
        this.currentBreakpoint = this.getCurrentBreakpoint();
        window.addEventListener('resize', this.onResize.bind(this));
    }

    initDomElements() {
        this.DOM.left = this.$el.querySelector('.left');
        this.DOM.center = this.$el.querySelector('.center');
        this.DOM.right = this.$el.querySelector('.right');
    }

    initResponsiveColumns() {
        this.updateColumns();
    }

    updateColumns() {
        const leftChildren = Array.from(this.DOM.left.children);
        const centerChildren = Array.from(this.DOM.center.children);
        const rightChildren = Array.from(this.DOM.right.children);

        if (window.matchMedia('(max-width: 879.98px)').matches) {
            // Mobile: all items in one column
            this.isMobile = true;
            this.isTablet = false;

            let allChildren = [...leftChildren, ...centerChildren, ...rightChildren];
            this.DOM.left.innerHTML = '';
            this.DOM.center.innerHTML = '';
            this.DOM.right.innerHTML = '';

            allChildren.forEach(child => {
                this.DOM.left.appendChild(child.cloneNode(true));
            });

        } else if (window.matchMedia('(min-width:879.98px) and (max-width: 1189.98px)').matches) {
            // Tablet: items in two columns
            this.isMobile = false;
            this.isTablet = true;

            let allChildren = [...leftChildren, ...centerChildren, ...rightChildren];
            this.DOM.left.innerHTML = '';
            this.DOM.center.innerHTML = '';
            this.DOM.right.innerHTML = '';

            allChildren.forEach((child, index) => {
                if (index % 2 === 0) {
                    this.DOM.left.appendChild(child.cloneNode(true));
                } else {
                    this.DOM.center.appendChild(child.cloneNode(true));
                }
            });

        } else {
            // Default: all items in original columns
            this.isMobile = false;
            this.isTablet = false;

            this.DOM.left.innerHTML = '';
            this.DOM.center.innerHTML = '';
            this.DOM.right.innerHTML = '';

            leftChildren.forEach(child => {
                this.DOM.left.appendChild(child);
            });
            centerChildren.forEach(child => {
                this.DOM.center.appendChild(child);
            });
            rightChildren.forEach(child => {
                this.DOM.right.appendChild(child);
            });
        }
    }

    onResize() {
        const newBreakpoint = this.getCurrentBreakpoint();
        if (newBreakpoint !== this.currentBreakpoint) {
            this.currentBreakpoint = newBreakpoint;
            this.updateColumns();
        }
    }

    getCurrentBreakpoint() {
        if (window.matchMedia('(max-width: 879.98px)').matches) {
            return 'mobile';
        } else if (window.matchMedia('(min-width:879.98px) and (max-width: 1189.98px)').matches) {
            return 'tablet';
        } else {
            return 'desktop';
        }
    }

    destroy() {
        window.removeEventListener('resize', this.onResize.bind(this));
    }
}
