import Swiper from "swiper";
import { Autoplay, FreeMode, Navigation } from 'swiper/modules';

import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, Autoplay, Navigation]);

export default class PushCustomerCase extends AbstractComponent {
    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM.slider = this.$el.querySelector('.swiper');
        this.DOM.sliderWrapper = this.$el.querySelector('.swiper-wrapper');
        this.DOM.slides = this.$el.querySelectorAll('.swiper-slide');
        this.DOM.arrows = this.$el.querySelector('.c-push-customer-cases__arrows');
        this.DOM.sliderArrowNext = this.$el.querySelector('.c-push-customer-cases__arrow.right');
        this.DOM.sliderArrowPrev = this.$el.querySelector('.c-push-customer-cases__arrow.left');
    }

    initSlider() {
        if (this.DOM.slider) {
            this.sliderObject = new Swiper(this.DOM.slider, {
                allowTouchMove: true,
                centeredSlides: false,
                slidesPerView: 1.35,
                spaceBetween: 15,
                grabCursor: true,
                breakpoints: {
                    879.98: {
                        spaceBetween: 20,
                        slidesPerView: 2.35,
                        allowTouchMove: this.DOM.slides.length > 2,
                    },
                    1189.98: {
                        spaceBetween: 20,
                        slidesPerView: 3.5,
                        allowTouchMove: this.DOM.slides.length > 3,
                    },
                },
                navigation: {
                    nextEl: this.DOM.sliderArrowNext,
                    prevEl: this.DOM.sliderArrowPrev,
                },
            });

            this.handleMediaQueries();
            window.addEventListener('resize', () => this.handleMediaQueries());
        }
    }

    handleMediaQueries() {
        const isBetween880And1189 = window.matchMedia('(min-width: 879.98px) and (max-width: 1189.98px)').matches;
        const isGreaterThan1189 = window.matchMedia('(min-width: 1189.98px)').matches;
        if (isBetween880And1189) {
            if (this.DOM.slides.length <= 2) {
                this.adjustSliderLayout();
            }else{
                this.removeSliderLayout();
            }
        }

        if (isGreaterThan1189) {
            if (this.DOM.slides.length <= 3) {
                this.adjustSliderLayout();
            }else{
                this.removeSliderLayout();
            }
        }
    }

    adjustSliderLayout() {
        this.DOM.sliderWrapper.style.justifyContent = 'center';
        this.DOM.arrows.style.display = 'none';
    
    }
    removeSliderLayout() {
        this.DOM.sliderWrapper.style.justifyContent = '';
        this.DOM.arrows.style.display = '';
    }
    destroy() {
    }
}
