import Swiper from "swiper";
import { Autoplay,FreeMode, Navigation } from 'swiper/modules';
import AbstractComponent from "./abstract-component";

Swiper.use([FreeMode, Autoplay,Navigation]);
  export default class testimony extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initSlider();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.slider = this.$el.querySelector('.swiper')
        this.DOM.sliderArrowNext = this.$el.querySelector('.c-testimony__arrow.right')
        this.DOM.sliderArrowPrev = this.$el.querySelector('.c-testimony__arrow.left')
    }
    initSlider(){
        if (this.DOM.slider) {
            this.sliderObject = new Swiper(this.DOM.slider, {
                allowTouchMove:true,
                centeredSlides:false,
                slidesPerView: 1,
                initialSlide:1,
                spaceBetween:30,
                breakpoints:{
                    '879.98': {
                        spaceBetween:20,
                        },
                  },
                navigation:{
                    nextEl:this.DOM.sliderArrowNext,
                    prevEl:this.DOM.sliderArrowPrev,
                  },
              });
        }
    }
    destroy() { }
}