import LocomotiveScroll from 'locomotive-scroll';
import AbstractComponent from "./abstract-component";
export default class LCS extends AbstractComponent {
	constructor(el) {
        super(el);
		this.init();
	}
	init() {
		this.createScroll();
	}
    createScroll() {
        this.scroll = new LocomotiveScroll({
            el: document.querySelector('main'),
            smooth: false,
            direction: 'vertical',
        });
        this.scroll.on('call',(value, way, obj)=>{

            if (value == "list-push" && way == "enter") {
                this.app.$menu.$el.classList.add('--scrolltop-disabled')
            }
            if (value == "list-push" && way == "exit") {
                this.app.$menu.$el.classList.remove('--scrolltop-disabled')
            } 
        })
    }
    disable() {
        this.scroll.stop();
    }
    enable() {
        this.scroll.start();
    }
    scrollTo(elt, offset = 0) {
        this.scroll.scrollTo(elt, { duration: 1000, easing: [0.90, 0.00, 0.00, 1.00], offset: offset });
    }
    update() {
        this.scroll.update();
    }
	destroy() {}
}
