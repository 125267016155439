import gsap from "gsap";
import AbstractComponent from "./abstract-component";

  export default class SiteFooter extends AbstractComponent {

    constructor(el) {
        super(el);
        this.DOM = {};
        this.initDomElements();
        this.initEvents();
    }

    initDomElements() {
        this.DOM = {}
        this.DOM.form = this.$el.querySelector( '.wpcf7' )
        this.DOM.formWrapper = this.$el.querySelector( '.form-wrapper' )
        this.DOM.sucessMessage = this.$el.querySelector( '.success-message' )
    }
    initEvents(){
        if (this.DOM.form) {
            this.DOM.form.addEventListener('wpcf7mailsent',()=>{
                gsap.to(this.DOM.formWrapper,{autoAlpha:0,duration:0.5,onComplete:()=>{
                    gsap.set(this.DOM.formWrapper,{display:'none'})
                    gsap.set(this.DOM.sucessMessage,{display:'block'})
                    gsap.fromTo(this.DOM.sucessMessage,{x:5},{x:0,duration:0.5})
                }})
            })   
        }
    }
    destroy() { }
}