export default class SiteMenu {

    constructor(app) {
        this.className = 'c-site-menu'
        this.tl = null
        this.app = app
        this.lastScroll = -10
        this.DOM = {};
        this.initDomElements();
        this.iniEvents();

    }

    initDomElements() {
        this.$el = document.querySelector('.' + this.className)
        this.DOM = {}
        this.DOM.burger = this.$el.querySelector('.' + this.className + '__burger')
        this.DOM.navMobile = this.$el.querySelector('.' + this.className + '__nav-list')
        this.DOM.links = this.$el.querySelectorAll('.' + this.className + '__nav-list >li')
        this.DOM.navRight = this.$el.querySelector('.' + this.className + '__nav-right')
        this.DOM.langswitcher = this.$el.querySelector('.' + 'c-select select')
    }
    iniEvents() {
        this.DOM.burger.addEventListener('click', () => {
            this.tl = this.app.gsap.timeline({ paused: true })
            this.DOM.burger.classList.toggle('active')
            const active = this.$el.classList.toggle('opened')
            if (active) {
                this.app.emit('disable-scroll')
                this.app.gsap.set(this.DOM.navRight, { visibility:'visible'})
                this.tl.to(this.DOM.burger, { pointerEvents: "none", duration: 0 })
                this.tl.fromTo(this.DOM.navMobile, { clipPath: 'polygon(0 0, 100% 0, 100% 0, 0 0)' }, { clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', duration: 1.5, ease: "0.14, 1.00, 0.34, 1.00" })
                this.tl.fromTo(this.DOM.links, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: "0.14, 1.00, 0.34, 1.00" }, "-=1.5")
                this.tl.fromTo(this.DOM.links, { x: -20, autoAlpha: 0 }, { x: 0, autoAlpha: 1, duration: 1.2, ease: "0.14, 1.00, 0.34, 1.00" }, "-=1.5")
                this.tl.to(this.DOM.navRight, { y: 0, autoAlpha: 1, duration: 1, ease: "0.14, 1.00, 0.34, 1.00" }, "-=1")
                this.tl.to(this.DOM.burger, { pointerEvents: "all", duration: 0 })
            } else {
                this.tl.to(this.DOM.burger, { pointerEvents: "none", duration: 0 })
                this.tl.to(this.DOM.navRight, { y: "100%", autoAlpha: 0, duration: 1, ease: "0.14, 1.00, 0.34, 1.00" })
                this.tl.fromTo(this.DOM.links, { x: 0 }, { x: 15, duration: 1, ease: "0.14, 1.00, 0.34, 1.00" })
                this.tl.fromTo(this.DOM.links, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.5, ease: "0.14, 1.00, 0.34, 1.00" }, "-=2")
                this.tl.fromTo(this.DOM.navMobile, { clipPath: 'inset(0 0 0)' }, { clipPath: 'inset(0 0 100%)', duration: 1, ease: "0.14, 1.00, 0.34, 1.00" }, "-=2")
                this.tl.to(this.DOM.burger, { pointerEvents: "all", duration: 0 })
                this.app.emit('enable-scroll')
            }
            this.tl.play();
        })
        this.DOM.langswitcher.addEventListener('change', () => {
            window.location = this.DOM.langswitcher.value
        })
        if (!document.body.classList.contains('template-customer-cases') && !document.body.classList.contains('template-articles')) {
            this.handleScroll();
            this.app.on("scroll", this.handleScroll.bind(this));
            this.DOM.links.forEach(link => {
                link.addEventListener('mouseenter', () => {
                    this.app.emit("add-bg-navbar")
                })
                link.addEventListener('mouseleave', () => {
                    this.app.emit("remove-bg-navbar")
                })
            }); 
        }else{
            this.app.emit("add-bg-navbar")
            this.handleScrollNoBg();
            this.app.on("scroll", this.handleScrollNoBg.bind(this));
        }


    }
    handleScrollNoBg() {
        if (window.scrollY > this.lastScroll) {
            this.$el.classList.remove("--scrolltop");
        } else if (window.scrollY < this.lastScroll) {
            this.$el.classList.add("--scrolltop");
        }
        if (window.scrollY > 20) {
            this.$el.classList.add("--scrolling");
        } else {
            this.$el.classList.remove("--scrolling");
        }
        this.lastScroll = window.scrollY;
    }
    
    handleScroll() {
        if (window.scrollY > this.lastScroll) {
            this.$el.classList.remove("--scrolltop"
            );
        } else if (window.scrollY < this.lastScroll) {
            this.$el.classList.add("--scrolltop"
            );
        }
        if (window.scrollY > 20) {
            this.$el.classList.add("--scrolling"
            );
            this.$el.querySelector('.c-site-menu__container').classList.add('bg-white'
        );
        } else {
            this.$el.classList.remove("--scrolling", 'bg-white'
            );
            this.$el.querySelector('.c-site-menu__container').classList.remove('bg-white')
        }
        this.lastScroll = window.scrollY
    }

    destroy() { }
}
